
import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { PackageService, FamilyService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION, DoctorTitle_TYPE } from "src/enums";
import globalConfig from "src/config/index";

@Component
export default class PhysicalPackageList extends Vue
{
    

     /**
     * 品牌列表
     * @private
     * @returns Array<any>
     */
     private hospBrandList: Array<any> = []

    /**
     * 当前选中用户
     * @protected
     * @returns string
     */
    protected currentUser: any = {};

    /**
     * 
     * @protected
     * @returns string
     */
     protected currentIndex: any = ''

    /**
     * 获取用户列表
     * @protected
     * @returns Array<any>
     */
    protected userList: Array<any> = [];

    /**
     * 选择人员
     * @protected
     * @returns boolean
     */
    protected showUserPopup: boolean = false;

    /**
     * 是否显示本人
     */
    protected isShowSelf: boolean = false;

    /**
     * 体检数据
     * @protected
     * @returns Array<any>
     */
    protected medicalList: Array<any> = [];
    
        protected allMedicalList: Array<any> = [];

    /**
     * 获取当前列表类型
     * @private
     * @returns any
     */
    protected get packageType(): any
    {
        return this.$route.query.type || null;
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 获取当前来源页面人员id
     * @private
     * @returns any
     */
    protected get employerMemberId(): any
    {
        return this.$route.query.employerMemberId || null;
    }

    /**
     * loading次数
     * @private
     * @returns string
     */
    private get loadingCount(): any
    {
        return this.$store.state.loadingCount || 0;
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    protected get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 获取企业参数配置
     * @private
     * @returns any
     */
    private get generalSettings(): any
    {
        let generalSettingsValue = localStorage.getItem("generalSettings");
        if(generalSettingsValue)
        {
            return JSON.parse(generalSettingsValue);
        }
        return [];
    }

    /**
     * 获取与本人关系
     * @private
     * @returns string
     */
    protected getRelationship(value: any): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 跳转套餐详情
     * @private
     * @returns void
     */
    protected onPackageDetail(item: any): void
    {
        localStorage.setItem("selectUser", JSON.stringify(this.currentUser));
        this.$router.push({name: "orgList", query:{packageId: item.id}});
    }
   
    /**
     * 获取用户列表
     * @private
     * @returns void
     */
    protected getUserList(userList: any): void
    {
        this.userList = userList;
        let data = {
            employerMemberId: null,
        };
        // 家属预约
        if(userList.length >= 2 && this.packageType=='2')
        {
            if (this.employerMemberId) // 有来源人员id
            {
                data = userList.filter(res => res.employerMemberId == this.employerMemberId)[0];
            }
            else
            {
                data = userList[1];
            }
        }

        // 本人预约
        if(this.packageType=='1')
        {
            data = userList[0];
           
        }

        this.currentUser = data;
        if(data.employerMemberId)
        {
            this.getMedicalpackage(data.employerMemberId);
        }
        
    }

    /**
     * 当前选中用户
     * @private
     * @returns void
     */
    protected onSelectUser(item: any): void
    {
        this.currentUser = item;
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
    protected onConfirmUser(data: any): void
    {
        this.currentUser = data;
        this.getMedicalpackage(data.employerMemberId)
        this.showUserPopup = false;
    }

    /**
     * 获取体检套餐
     * @protected
     * @returns string
     */
    protected async getMedicalpackage(memberId: string): Promise<void>
    {
        try
        {
            let {content: result} = await PackageService.instance.getMedicalpackage(memberId);
            if(result.data)
            {
                let packageList = [];
                result.data.packageList.forEach(element => {
                    if (this.employerConfig.specialPackageIds.includes(element.id))
                    {
                        packageList.push(element);
                    }
                });
                this.medicalList = JSON.parse(JSON.stringify(packageList));
                this.allMedicalList = packageList;
                this.hospBrandList = result.data.brands;
            }
            
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 切换tab
     * @private
     * @param {number} number
     * @returns void
     */
     private onTabChange(id: any): void
    {
        this.medicalList = this.allMedicalList
        this.currentIndex = id
        if(id !=='')
        {
            this.medicalList = this.medicalList.filter((item) => {
                return  item.brandTypes.includes(id)
            
            })
        }
    }

    /**
     * 添加用户
     * @protected
     * @returns string
     */
    protected addUser(): void
    {
        this.$router.push({name: "familyList"});
    }

    /**
     * 是否显示配置
     * @protected
     * @returns boolean
     */
    protected showStting(code: string): boolean
    {
        // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
        // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
        let data = this.generalSettings.filter(res => res.code == code);
        if (data.length > 0)
        {
            return data[0].isOpen;
        }
        else
        {
            return true;
        }
    }
    

}
